export enum JobType {
  jobType0,
  jobType1,
  jobType2,
  jobType3,
  jobType4,
  jobType5,
  jobType6,
  jobType7,
  jobType8,
  jobType9,
}
