import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SharedPipesModule } from 'app/shared-pipes/shared-pipes.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularMaterialSharedModule } from 'app/angular-material-shared/angular-material-shared.module';

@NgModule({
  imports: [
    CommonModule,
    SharedPipesModule,
    FormsModule,
    ReactiveFormsModule,
    AngularMaterialSharedModule,
  ],
  declarations: [ResetPasswordComponent],
  exports: [ResetPasswordComponent],
})
export class ResetPasswordModule {}
