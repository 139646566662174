import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartjsComponent } from './components/chartjs/chartjs.component';
import { ChartService } from './chart.service';

@NgModule({
  declarations: [ChartjsComponent],
  imports: [CommonModule],
  providers: [ChartService],
  exports: [ChartjsComponent],
})
export class ChartModule {}
