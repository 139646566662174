import { Injectable } from '@angular/core';

// used "chart.js": "^2.9.4",
// import { Chart, ChartTypeRegistry, ChartConfiguration } from 'chart.js';

@Injectable({
  providedIn: 'root',
})
export class ChartService {
  constructor() {}

  // createChartConfiguration(
  //   datasets,
  //   type: keyof ChartTypeRegistry,
  //   options: any = {
  //     animation: false,
  //     plugins: {
  //       legend: {
  //         display: true,
  //       },
  //       tooltip: {
  //         enabled: true,
  //       },
  //     },
  //   }
  // ): ChartConfiguration {
  //   return {
  //     type: type,
  //     options: options,
  //     data: datasets,
  //   };
  // }
}
